import { useState, useEffect } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";

function Login() {

  return (
    <div className="main_wrapper">
      <Header />
      <div className="main_container">
        <div class="form-horizontal" >
          <div class="form-group d-md-flex">
            <label class="control-label col-sm-4 col-md-3 col-12 col-lg-3" for="email">Username:</label>
            <div class="col-sm-10 col-12 col-lg-8 col-md-6">
              <input type="text" class="form-control" id="username" />
            </div>
          </div>
          <div class="form-group d-md-flex">
            <label class="control-label col-sm-4 col-md-3 col-12 col-lg-3" for="pwd">Password:</label>
            <div class="col-sm-10 col-12 col-lg-8 col-md-6">
              <input type="password" class="form-control" id="pwd" />
            </div>
          </div>
          <div class="form-group d-md-flex">
          <label class="control-label col-sm-4 col-md-3 col-12 col-lg-3" for="pwd"></label>
            <div class="col-sm-10 col-12 col-lg-8 col-md-6">
              <button type="submit" class="login-btn">Login</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
